import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import { HiUsers } from "react-icons/hi2";
import { HiMiniSignal } from "react-icons/hi2";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowForward } from "react-icons/io";
import { LuUser } from "react-icons/lu";
import { FaVideo } from "react-icons/fa6";
import { HiOutlineFilm } from "react-icons/hi2";
import { BiSolidCategory } from "react-icons/bi";
import { MdBroadcastOnHome } from "react-icons/md";
import { RiAdvertisementFill } from "react-icons/ri";
import { RiAdvertisementLine } from "react-icons/ri";
import { TbPackages } from "react-icons/tb";
// import { MdSpaceDashboard } from "react-icons/md";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";

import { FiSettings } from "react-icons/fi";
// import User from '../User/User';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosBusiness } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi2";
import { BsDatabase } from "react-icons/bs";
import { MdFeaturedPlayList } from "react-icons/md";
import User from "../User/User";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

export default function Sidebar({ children }) {
  const matches = useMediaQuery("(min-width:1199px)");
  const [open, setOpen] = useState(true);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const navigate = useNavigate();
  let location = window.location.href;

  function activeRoute(res) {
    if (location.includes(res)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (matches) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [matches]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          sx={{ background: "white" }}
          position="fixed"
          open={!matches ? false : open}
        >
          <Toolbar className="d-flex justify-content-between align-items-center">
            {open ? <div></div> :
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setOpen(!open)}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <RxHamburgerMenu color="#526484" size={26} />
                </IconButton>
                <img src="/images/logo.png" alt="" width='40px' />
              </div>}
            <div
              className="pointer text-black pe-md-4 pe-sm-2 pe-0 mt-1"
              style={{ marginTop: "-10px" }}
            >
              <User />
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: '#101924',
              color: '#526484',
            },

          }}
          variant={!matches ? "temporary" : "persistent"}
          open={open}
          onClose={() => setOpen(!open)}
        >
          <DrawerHeader className="d-flex justify-content-start gap-3 ps-3">
            <IconButton
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ ml: 1.2 }}
            >
              <RxHamburgerMenu color="#526484" size={26} />
            </IconButton>
            <img src="/images/logo.png" alt="" width='40px' />
          </DrawerHeader>
          <Divider sx={{ backgroundColor: '#526484', color: '#526484' }} />

          <List className="mt-3">

            <ListItem className="pb-0">
              <ListItemButton
                className={`${activeRoute("userlisting") ? "activeTab" : ""
                  } rounded`}
                // onClick={() => navigate("/userlisting")}
                onClick={() => setToggle1(!toggle1)}
              >
                <ListItemIcon>
                  <HiUsers
                    size={20}
                    color={activeRoute("userlisting") ? "#3B76EF" : "#526484"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="w-100 d-flex justify-content-between align-items-center">
                    Users
                    {toggle1 ? (
                      <IoIosArrowUp className="ms-2" />
                    ) : (
                      <IoIosArrowForward className="ms-2" />
                    )}
                  </div>}
                />
              </ListItemButton>
            </ListItem>
            <div
              className={`px-3 ps-5`}
              style={{
                height: toggle1 ? "50px" : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
              }}
            >
              <List className="">
                <ListItemButton
                  className={` ${activeRoute("userlisting") ? "activeTab" : ""
                    } rounded`}
                  onClick={() => navigate("/userlisting")}
                >
                  <ListItemIcon>
                    <LuUser
                      size={16}
                      color={activeRoute("userlisting") ? "#3B76EF" : "#526484"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<div className="f-15">Users</div>}
                  />
                </ListItemButton>
              </List>
            </div>
            <ListItem className="pb-0">
              <ListItemButton
                className={`${activeRoute("userlisting") ? "activeTab" : ""
                  } rounded`}
                // onClick={() => navigate("/userlisting")}
                onClick={() => setToggle2(!toggle2)}
              >
                <ListItemIcon>
                  <FaVideo
                    size={20}
                    color={activeRoute("media") ? "#3B76EF" : "#526484"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="w-100 d-flex justify-content-between align-items-center">
                    Media
                    {toggle2 ? (
                      <IoIosArrowUp className="ms-2" />
                    ) : (
                      <IoIosArrowForward className="ms-2" />
                    )}
                  </div>}
                />
              </ListItemButton>
            </ListItem>
            <div
              className={`px-3 ps-5`}
              style={{
                height: toggle2 ? "160px" : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
              }}
            >
              <List className="">
                <ListItemButton
                  className={` ${activeRoute("userlisting") ? "activeTab" : ""
                    } rounded`}
                  onClick={() => navigate("/userlisting")}
                >
                  <ListItemIcon>
                    <HiOutlineFilm
                      size={16}
                      color={activeRoute("userlisting") ? "#3B76EF" : "#526484"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<div className="f-15">Genre</div>}
                  />
                </ListItemButton>
                <ListItemButton
                  className={` ${activeRoute("userlisting") ? "activeTab" : ""
                    } rounded`}
                  onClick={() => navigate("/userlisting")}
                >
                  <ListItemIcon>
                    <BiSolidCategory
                      size={16}
                      color={activeRoute("userlisting") ? "#3B76EF" : "#526484"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<div className="f-15">Category</div>}
                  />
                </ListItemButton>
                <ListItemButton
                  className={` ${activeRoute("userlisting") ? "activeTab" : ""
                    } rounded`}
                  onClick={() => navigate("/userlisting")}
                >
                  <ListItemIcon>
                    <MdBroadcastOnHome
                      size={16}
                      color={activeRoute("userlisting") ? "#3B76EF" : "#526484"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<div className="f-15">Broadcaster</div>}
                  />
                </ListItemButton>
              </List>
            </div>

            <ListItem className="pb-0">
              <ListItemButton
                className={`${activeRoute("channel") ? "activeTab" : ""
                  } rounded`}
                onClick={() => navigate("/channel")}
              >
                <ListItemIcon>
                  <HiMiniSignal
                    size={20}
                    color={activeRoute("channel") ? "#3B76EF" : "#526484"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="">Live Management</div>}
                />
              </ListItemButton>
            </ListItem>
            <ListItem className="pb-0">
              <ListItemButton
                className={`${activeRoute("packageListing") ? "activeTab" : ""
                  } rounded`}
                onClick={() => navigate("/packageListing")}
              >
                <ListItemIcon>
                  <TbPackages
                    size={20}
                    color={activeRoute("packageListing") ? "#3B76EF" : "#526484"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="">Packages</div>}
                />
              </ListItemButton>
            </ListItem>
            <ListItem className="pb-0">
              <ListItemButton
                className={`${activeRoute("advertisements") ? "activeTab" : ""
                  } rounded`}
                  onClick={() => setToggle3(!toggle3)}
              >
                <ListItemIcon>
                  <RiAdvertisementFill
                    size={20}
                    color={activeRoute("advertisements") ? "#3B76EF" : "#526484"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="w-100 d-flex justify-content-between align-items-center">
                    Advertisements
                    {toggle3 ? (
                      <IoIosArrowUp className="ms-2" />
                    ) : (
                      <IoIosArrowForward className="ms-2" />
                    )}
                    </div>}
                />
              </ListItemButton>
            </ListItem>
             <div
              className={`px-3 ps-5`}
              style={{
                height: toggle3 ? "50px" : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
              }}
            >
              <List className="">
                <ListItemButton
                  className={` ${activeRoute("banners") ? "activeTab" : ""
                    } rounded`}
                  onClick={() => navigate("/advertisements/banners")}
                >
                  <ListItemIcon>
                    <RiAdvertisementLine
                      size={16}
                      color={activeRoute("banners") ? "#3B76EF" : "#526484"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<div className="f-15">Banners</div>}
                  />
                </ListItemButton>
              </List>
            </div>


          </List>
        </Drawer>

        <Main open={!matches ? true : open}>
          <div className={style.mainContainer}>
            <div className={style.topbar}></div>
            <div className={`${style.content} p-md-4 p-sm-3 p-2`}>
              {children}
            </div>
          </div>
        </Main>
      </Box>
    </>
  );
}
