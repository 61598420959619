// export let baseUrl = "https://ziggsmsbackend.onrender.com";
export let baseUrl = "https://masterbackend-9c5c87c380fd.herokuapp.com";
// export const baseUrl = 'http://localhost:4002';

export const API = {
  plan: {
    addPlan: "/plan/addPlan",
    deletePlan: "/plan/deletePlanById/",
    getPlanByCreator: "/plan/getPlanByCreator/",
    getPlanById: "/plan/getPlanById/",
    bulkInsertPlan: "/plan/bulkInsertPlan",
    statusChanges: "/plan/updatePlanStatus/",
    getAllChannel: "/channel/getAllChannel",
    deleteChannel:"/channel/deleteChannel/",
    // getAllChannelByCategory: "/channel/getAllChannelByCategory",
    getchannelCategory: "/channel/getCategories",
    addCategory: "/channel/addCategory",
    addChannel: "/channel/addChannel",
    // channelByFilter: "/channel/getChannelByFilter",
    getAllPlans: "/plan/getAllPlans",
    getAllChannelByCategory: "/channel/getAllChannelByCategory",
    getChannelById: "/channel/getChannelById/",
    updateChannelById: "/channel/updateChannel/",
    updatePlan: "/plan/updatePlanById",
  },

  user: {
    addUser: "/ispMember/addMember",
    getAllUser: "/ispMember/getAllIspMember",
    getUserById: "/ispMember/getMemberById/",
    deleteUser: "/ispMember/deleteIspMember/",
    updateUser: "/ispMember/updateMember/",
    updateMemberStatus: "/ispMember/updateMemberStatus/",
    uploadFile: "/upload/uploadDocuments",
  },

  subscriberManagement: {
    addSubscriber: "/subscriber/addNewConnection",
    getAllSubscriber: "/subscriber/getAllSubscriberNew",
    getSubscriberById: "/subscriber/getSubscriberById/",
    updateSubscriber: "/subscriber/updateSubscriber/",
    deleteSubscriber: "/subscriber/deleteSubscriberById/",
    updateSubscriberStatus: "/subscriber/updateStatusById/",
  },
  invoice: {
    addInvoices: "/invoice/addInvoice",
    getInvoiceById: "/invoice/getInvoiceByCreator/",
    viewDetailInvoice: "/invoice/getInvoiceById/id",
    getInvoicePdfById: "/invoice/getInvoiceById/",
  },
  role: {
    addRole: "/role/superAdmin/addRole",
    getAllRole: "/role/superAdmin/getAllRoleData",
    deleteRole: "/role/superAdmin/deleteRole/",
    updatePermission: "/role/superAdmin/updateRolePermissionById/",
    getRoleDatabyId: "/role/getRoleDatabyId/",
    getPermissionsByRole: "/role/superAdmin/getPermissionByRole",
  },
  auth: {
    login: "/auth/login",
    forgetPassword: "/auth/forgetPassword",
    changePassword: "/auth/changePassword",
    checkUserTokenIsValid: "/auth/checkUserTokenIsValid",
    SendOtp: "/auth/sendOtp",
    VerifyOtp: "/auth/verifyOtp",
  },
  logs: {
    getEventLogsAll: "/log/getEventLogsAll",
  },
  document: {
    upload: "/upload/uploadDocuments",
    deleteDocs: "/upload/deleteMultipleDocData",
  },
  language: {
    allLanguage: "/language/getAllLanguages",
    addLanguage: "/language/addLanguage",
    updateLanguage: "/language/updateLanguageById/",
    deleteLanguage: "/language/deleteLanguageById/",
  },

  genre: {
    allGenre: "/genre/getAllgenre",
    addGenre: "/genre/addgenre",
    updateGenre: "/genre/updategenreById/",
    deleteGenre: "/genre/deletegenreById/",
  },

  broadcaster: {
    getAllBroadcaster: "/broadcaster/getAllBroadcaster",
    addBroadcaster: "/broadcaster/addBroadcaster",
    updateBroadcaster: "/broadcaster/updateBroadcaster/",
    deletBroadcaster: "/broadcaster/deleteBroadcaster/",
  },
};
