import { startLoader, stopLoader } from "../Store/LoaderSlice";
import { error, success } from "../Store/SnackbarSlice";
import { store } from "../Store/store";

export const loader = {
    start: () => store.dispatch(startLoader()),
    stop: () => store.dispatch(stopLoader())
}

export const snackbar = {
    error: (msg) => store.dispatch(error({ show: true, msg})),
    success: (msg) => store.dispatch(success({ show: true, msg}))
}
