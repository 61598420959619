import React, { useEffect, useState } from 'react'
import TableContainer from '../../Components/TableContainer/TableContainer';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { getCollectionData } from '../../Firebase/cloudFirestore/getData';
import { loader } from '../../utils';
import { AiTwotoneDelete } from "react-icons/ai";
import { Modal, ModalBody } from 'reactstrap';
import { GoInfo } from "react-icons/go";

export default function Banners() {
    const [searchInput, setSearchInput] = useState("");
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalData, setTotalData] = useState(0);
    
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')


    const getEvent = async () => {
        loader.start();
        const eventData = await getCollectionData("Users");
        let newRes = eventData.map((res, index) => {
            return {
                ...res,
                sno: index + 1
            }
        });

        setAllData(newRes);
        setData(newRes);
        setTotalData(newRes?.length || 0);
        paginate(newRes, page);
        loader.stop();
    };

    function paginate(eventData, cpage) {
        const filteredData = eventData.filter(
            (res) =>
                res.Name &&
                typeof res.Name === "string" &&
                res.Name.toLowerCase().includes(searchInput.toLowerCase())
        );
        const startIndex = cpage * rowsPerPage;
        const slicedData = filteredData.slice(startIndex, startIndex + rowsPerPage);
        setData(slicedData);
    }

    useEffect(() => {
        getEvent();
    }, [rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        paginate(allData, newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function deleteBanner() {
        loader.start();
        try {

        } catch (err) {
            console.log(err)
        } finally {
            loader.stop();
        }
    }

    return (
        <>
            <Modal centered isOpen={deleteModal}>
                <ModalBody>
                    <div className='py-4'>
                        <div className='d-flex justify-content-center'>
                            <GoInfo className='text-danger' size={40} />
                        </div>
                        <div className='text-center mt-3'>Are you sure want to delete this banner image?</div>
                    </div>
                    <div className='d-flex justify-content-end gap-3'>
                        <button className='btn btn-sm border' onClick={() => {
                            setDeleteModal(false)
                            setDeleteId('')
                        }}>Cancel</button>
                        <button className='btn btn-sm btn-primary' onClick={deleteBanner}>Delete</button>
                    </div>
                </ModalBody>
            </Modal>
            <h2 className="fw-600">Banners</h2>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-primary btn-radius px-3'>Add Banner</button>
            </div>
            <div className='mt-4'>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>SNo.</TableCell>
                                <TableCell>Banner</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data?.map((res, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell
                                            className="pointer text-capitalize"
                                            style={{ width: '25%' }}
                                        >
                                            {res?.sno}
                                        </TableCell>
                                        <TableCell><img src='/images/signupbg.png' width='200px' alt='' /></TableCell>
                                        <TableCell><AiTwotoneDelete size={20} className='text-primary pointer' onClick={() => {
                                            setDeleteModal(true)
                                            setDeleteId('')
                                        }} /></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        component="div"
                        count={totalData}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </div>
        </>
    )
}
