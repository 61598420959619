import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import TableContainer from "../TableContainer/TableContainer";

import Style from "./style.module.css";

import {
  Backdrop,
  Box,
  CircularProgress,
  FormGroup,
  TablePagination,
} from "@mui/material";

import {
  getCollectionData,
  getDocumentData,
} from "../../Firebase/cloudFirestore/getData";

import { updateDocument } from "../../Firebase/cloudFirestore/updateData";
import { useNavigate, useParams } from "react-router-dom";

const SubscribersMangement = ({ data }) => {

  const {id}=useParams();

  const [subscriptionStates, setSubscriptionStates] = useState({
    homeAutomationSubscription: data?.homeAutomationSubscription || false,
    freeTv: data?.freeTv || false,
    iptvSubscription: data?.iptvSubscription || false,
    cctvSubscription: data?.cctvSubscription || false,
    broadbandSubscription: data?.broadbandSubscription || false,
  }); // Manage toggle states

  const navigate = useNavigate();

  const subscriptionItems = [
    {
      label: "Home Automation Subscription",
      key: "homeAutomationSubscription",
    },
    { label: "Free TV", key: "freeTv" },
    { label: "Zigg Tv Subscription", key: "iptvSubscription" },
    { label: "CCTV Subscription", key: "cctvSubscription" },
    { label: "Broadband Subscription", key: "broadbandSubscription" },
  ];

  const handleSubscriptionChange = async (key, checked) => {
    setSubscriptionStates((prevState) => ({
      ...prevState,
      [key]: checked,
    }));

    try {
      const updatedData = { [key]: checked };
      await updateDocument("Users", id, updatedData);
      console.log(`Updated ${key} to ${checked}`);
    } catch (err) {
      console.error("Error updating subscription:", err);
    }
  };

  // useEffect(() => {
  //   getEvent();
  // }, []);

  useEffect(() => {
    setSubscriptionStates({
      homeAutomationSubscription: data?.homeAutomationSubscription || false,
      freeTv: data?.freeTv || false,
      iptvSubscription: data?.iptvSubscription || false,
      cctvSubscription: data?.cctvSubscription || false,
      broadbandSubscription: data?.broadbandSubscription || false,
    });
  }, [data]);

  return (
    <>
      <div className="">
        <div className={`p-3`}>
          <FormGroup className="d-flex flex-row">
            <div
              className={`d-flex flex-column overflow-auto custom_scrollbar w-100`}
            >
              <div className="fs-5 fw-600 text-secondary">
                Subscription Details
              </div>

              <Box sx={{ m: 1 }} className="">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Subscription Type</TableCell>
                      <TableCell>Subscription Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {subscriptionItems.map((item) => (
                      <TableRow key={item.key}>
                        <TableCell className="text_cap">{item.label}</TableCell>
                        <TableCell>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={!!subscriptionStates[item.key]}
                              // checked={true}
                              onChange={(e) =>
                                handleSubscriptionChange(
                                  item.key,
                                  e.target.checked,
                                  
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default SubscribersMangement;
