import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import TableContainer from "../TableContainer/TableContainer";

import Style from "./style.module.css";

import {
  Backdrop,
  Box,
  CircularProgress,
  FormGroup,
  TablePagination,
} from "@mui/material";

import classnames from "classnames";
import { db } from "../../Firebase/firebase";
import {
  getCollectionData,
  getDocumentData,
} from "../../Firebase/cloudFirestore/getData";

import { updateDocument } from "../../Firebase/cloudFirestore/updateData";
import { deleteDocument } from "../../Firebase/cloudFirestore/deleteData";
import { Button, Modal, ModalHeader, Spinner } from "reactstrap";

import { Close, Delete } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import { RiDeleteBin5Line } from "react-icons/ri";
import DropdownWithEditDelete from "../testing";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { loader } from "../../utils";

const UsersListing = () => {
  const [storeData, setStoreData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [subscriptionStates, setSubscriptionStates] = useState({}); // Manage toggle states
  const [totalData, setTotalData] = useState(0);
  const navigate = useNavigate();

  const getEvent = async () => {
    loader.start();
    const eventData = await getCollectionData("Users");
    eventData.sort(
      (a, b) => b?.created_at.toMillis() - a?.created_at.toMillis()
    );

    setAllData(eventData);
    setData(eventData);
    setTotalData(eventData?.length || 0);
    paginate(eventData, page);
    loader.stop();
  };

  function filterData(e) {
    setPage(0);
    const filteredData = allData.filter((res) =>
      res.Name.toLowerCase().includes(e.toLowerCase())
    );
    console.log(filteredData, "this is filtered Data");
    setTotalData(filteredData?.length || 0);
    paginate(filteredData, page);
  }

  function paginate(eventData, cpage) {
    console.log(cpage);
    const filteredData = eventData.filter(
      (res) =>
        res.Name &&
        typeof res.Name === "string" &&
        res.Name.toLowerCase().includes(searchInput.toLowerCase())
    );
    const startIndex = cpage * rowsPerPage;
    const slicedData = filteredData.slice(startIndex, startIndex + rowsPerPage);
    setData(slicedData);
  }

  useEffect(() => {
    getEvent();
  }, [rowsPerPage]); // Include currentPage as a dependency

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(newPage);
    paginate(allData, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box>
          <h2 className="fw-600">User Management</h2>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div></div>
          <div className={`d-flex gap-4`}>
            <div className="">
              <input
                type="search"
                placeholder="Search"
                className="form-control w-100"
                onChange={(e) => {
                  if (e.target.value == " ") {
                    e.target.value = "";
                  } else {
                    filterData(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Box>

      <div className="mt-4">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Phone No.</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((res, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell
                      className="pointer text-capitalize"
                      onClick={() => navigate(`view/${res?.id}`)}
                    >
                      {res?.Name}
                    </TableCell>

                    <TableCell className="text-capitalize">
                      {res?.EmailId}
                    </TableCell>
                    <TableCell className="text-capitalize">
                      {res?.created_at &&
                        res.created_at.toDate().toLocaleDateString()}
                    </TableCell>

                    <TableCell>{res?.PhoneNo}</TableCell>
                    <TableCell>{res?.Address ? res?.Address : "n/a"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={totalData}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
};

export default UsersListing;
