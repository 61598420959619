import React, { useEffect, useState } from 'react'
import TableContainer from '../../Components/TableContainer/TableContainer';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { getCollectionData } from '../../Firebase';
import { useNavigate } from 'react-router';
import { loader } from '../../utils';

export default function PackageListing() {
    const [storeData, setStoreData] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [subscriptionStates, setSubscriptionStates] = useState({}); // Manage toggle states
    const [totalData, setTotalData] = useState(0);
    const navigate = useNavigate();
  
    const getEvent = async () => {
      loader.start();
      const eventData = await getCollectionData("Users");
      eventData.sort(
        (a, b) => b?.created_at.toMillis() - a?.created_at.toMillis()
      );
  
      setAllData(eventData);
      setData(eventData);
      setTotalData(eventData?.length || 0);
      paginate(eventData, page);
      loader.stop();
    };
  
    function filterData(e) {
      setPage(0);
      const filteredData = allData.filter((res) =>
        res.Name.toLowerCase().includes(e.toLowerCase())
      );
      console.log(filteredData, "this is filtered Data");
      setTotalData(filteredData?.length || 0);
      paginate(filteredData, page);
    }
  
    function paginate(eventData, cpage) {
      console.log(cpage);
      const filteredData = eventData.filter(
        (res) =>
          res.Name &&
          typeof res.Name === "string" &&
          res.Name.toLowerCase().includes(searchInput.toLowerCase())
      );
      const startIndex = cpage * rowsPerPage;
      const slicedData = filteredData.slice(startIndex, startIndex + rowsPerPage);
      setData(slicedData);
    }
  
    useEffect(() => {
      getEvent();
    }, [rowsPerPage]); // Include currentPage as a dependency
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      console.log(newPage);
      paginate(allData, newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  return (
    <>
     <h2 className="fw-600">Packages</h2>
     <div className='row mt-4'>
        <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
            <input type='search' placeholder='Search' className='form-control'/>
        </div>
     </div>
     <div className="mt-4">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Plan Type/Category</TableCell>
                <TableCell>Channels</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            {/* <TableBody>
              {data?.map((res, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell
                      className="pointer text-capitalize"
                      onClick={() => navigate(`view/${res?.id}`)}
                    >
                      {res?.Name}
                    </TableCell>

                    <TableCell className="text-capitalize">
                      {res?.EmailId}
                    </TableCell>
                    <TableCell className="text-capitalize">
                      {res?.created_at &&
                        res.created_at.toDate().toLocaleDateString()}
                    </TableCell>

                    <TableCell>{res?.PhoneNo}</TableCell>
                    <TableCell>{res?.Address ? res?.Address : "n/a"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody> */}
          </Table>

          <TablePagination
            component="div"
            count={totalData}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  )
}
